import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic,faUnderline, faCode,faHeading, faListUl, faListOl, faLink, faImage, faQuoteLeft, faTable, faStrikethrough, faSquare } from '@fortawesome/free-solid-svg-icons';
import './Toolbar.css';

const Toolbar = ({ onFormat }) => {
  return (
    <div className="toolbar">
      <button onClick={() => onFormat('bold')}>
        <FontAwesomeIcon icon={faBold} title="Bold" />
      </button>
      <button onClick={() => onFormat('italic')}>
        <FontAwesomeIcon icon={faItalic} title="Italic" />
      </button>
      <button onClick={() => onFormat('underline')}>
        <FontAwesomeIcon icon={faUnderline} title="Underline" />
      </button>
      <div className="dropdown">
    <button className="dropdown-toggle">
      <FontAwesomeIcon icon={faHeading} title="Headings" />
    </button>
    <div className="dropdown-content">
      <button onClick={() => onFormat('heading1')}>Title 1</button>
      <button onClick={() => onFormat('heading2')}>Title 2</button>
      <button onClick={() => onFormat('heading3')}>Title 3</button>
    </div>
      </div>

      
      <button onClick={() => onFormat('blockCode')}>
        <FontAwesomeIcon icon={faCode} title="Code Block" />
      </button>
      <button onClick={() => onFormat('link')}>
        <FontAwesomeIcon icon={faLink} title="Link" />
      </button>
      <button onClick={() => onFormat('image')}>
        <FontAwesomeIcon icon={faImage} title="Image" />
      </button>
      <button onClick={() => onFormat('quote')}>
        <FontAwesomeIcon icon={faQuoteLeft} title="Blockquote" />
      </button>
      <button onClick={() => onFormat('list')}>
        <FontAwesomeIcon icon={faListUl} title="List" />
      </button>
      <button onClick={() => onFormat('orderedList')}>
        <FontAwesomeIcon icon={faListOl} title="Ordered List" />
      </button>
      <button onClick={() => onFormat('table')}>
        <FontAwesomeIcon icon={faTable} title="Table" />
      </button>
      <button onClick={() => onFormat('strikethrough')}>
        <FontAwesomeIcon icon={faStrikethrough} title="Strikethrough" />
      </button>
      <button onClick={() => onFormat('taskList')}>
        <FontAwesomeIcon icon={faSquare} title="Task List" />
      </button>
    </div>
  );
};

export default Toolbar;
