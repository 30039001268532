// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it appears on top */
}

/* Styles for the popup box */
.popup-box {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.popup-box h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.popup-box p {
  margin: 20px 0;
}

.popup-box button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-box button:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/components/Popup.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC,EAAE,gCAAgC;EACtE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,8BAA8B;AAC/C;;AAEA,6BAA6B;AAC7B;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Styles for the popup overlay */\n.popup-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensures it appears on top */\n}\n\n/* Styles for the popup box */\n.popup-box {\n  background-color: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n  width: 300px;\n  text-align: center;\n}\n\n.popup-box h2 {\n  margin-top: 0;\n  font-size: 1.5rem;\n}\n\n.popup-box p {\n  margin: 20px 0;\n}\n\n.popup-box button {\n  background-color: #4caf50;\n  color: white;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.popup-box button:hover {\n  background-color: #45a049;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
