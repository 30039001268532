import React from 'react';

const DownloadButton = ({ handleDownload, loading }) => {
  return (
    <button onClick={handleDownload} disabled={loading}>
      {loading ? 'Processing...' : 'Download Docx'}
    </button>
  );
};

export default DownloadButton;